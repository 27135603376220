import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import {TextField, Typography, Button, FormLabel,Toolbar} from '@material-ui/core';
import { Tabs, Tab } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import {FormGroup, FormControl} from '@material-ui/core';
import Select from '@material-ui/core/Select';
import TabContainer from './shares/react_tab_container';
import language from '../layout/language';



// Require Editor JS files.
import 'froala-editor/js/froala_editor.pkgd.min.js';
import 'froala-editor/js/third_party/font_awesome.min.js';
import 'froala-editor/js/third_party/ionicons.min.js';


// Require Editor CSS files.
import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';

// Require Font Awesome.
import 'froala-editor/css/third_party/font_awesome.min.css';
import 'froala-editor/css/third_party/ionicons.min.css';

import FroalaEditor from 'react-froala-wysiwyg';


import classNames from 'classnames';
import Utils from '../api/api';
import theme, {styles} from '../layout/theme';

class FAQDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      categories: [],
      _id: '',
      faq: {
        questionVN: '',
        answerVN: '',
        questionEN: '',
        answerEN: '',
        category: '',
        order: 1
      },
      error: {
        vn: false,
        en: false,
        //kr: false,
        questionVN_error: false,
        answerVN_error: false,
        questionEN_error: false,
        answerEN_error: false,
        //questionKR_error: false,
        //answerKR_error: false,
      },
      failed: false,
      message: '',
      isUpdate: false,
      tab: 0,
      options: {'empty':'empty'}
    }
  }
  componentDidMount() {
    //console.log(this.props.action)
    var $this = this;
    var params = this.context.router.route.match.params;

    Utils.getListData('faqgroups',1,100,'0,0',null,1,function(data) {
      //console.log(data);
      if (data.status === 'success') {
        $this.setState({categories: data.results});
      }
    });
    Utils.getBlockContents(function(options){
      $this.setState({options:options});
    });

    if (params.id !== undefined) {
      $this.setState({
        isUpdate: true
      }, function() {
        Utils.getSingleData('faqs',params.id, function(res) {
          if (res.status == 'success' && res.results!==undefined) {
            var data = {}, item = res.results;
            for(var property in item) {
              if(item.hasOwnProperty(property)) {
                data[property] = item[property];
              }
            }
            data._id = item._id;
            if(data.category === undefined || data.category === null) data.category = '';
            $this.setState({faq: data});
          }
        });
      });
    }
  }
  onEditorStateChange(field,value){
    var faq = this.state.faq,
        error = this.state.error;
    faq[field] = value;
    error[field+'_error'] = value=='';

    this.setState({faq:faq,error:error});

  }
  handleValidate(e,flag) {
    var $this = this,
        faq = this.state.faq,
        obj = this.state.error;

    faq[e.target.id] = e.target.value;
    //console.log(e.target.id);
    var p = e.target.id + '_error';
    //console.log(p);
    if (e.target.value.trim() == '' || e.target.value == null) {
      obj[p] = true;
    } else {
      obj[p] = false;
    }
    obj[flag] = false;

    this.setState({faq: faq, error:obj}, () => {
      if(!$this.state.error.questionVN_error && !$this.state.error.answerVN_error){
        obj['vn'] = false;
      }else{
        obj['vn'] = true;
      }
      if(!$this.state.error.questionEN_error && !$this.state.error.answerEN_error){
        obj['en'] = false;
      }else{
        obj['en'] = true;
      }
      // if(!$this.state.error.questionKR_error && !$this.state.error.answerKR_error){
      //   obj['kr'] = false;
      // }else{
      //   obj['kr'] = true;
      // }
      $this.setState({error:obj});
    });
  }
  _save(){
    var $this = this;
    var data = this.state.faq;
    if (this.state.isUpdate) {
      //data._id = this.state.id;
      Utils._update('faq',data, function(res) {
        //console.log(res);
        if (res.status == 'success') {
          $this.context.router.history.push('/faq/');
        } else {
          $this.setState({failed: true, message: JSON.stringify(res.message)});
        }
      });
    } else {
      Utils._add('faq',data, function(res) {

        if (res.status == 'success') {
          $this.context.router.history.push('/faq/');
        } else {
          $this.setState({failed: true, message: JSON.stringify(res.message)});
        }
      });
    }
  }
  handleSave(e,flag) {
    var params = this.context.router.route.match.params;

    if (params.id !== undefined && !this.state.isUpdate) {
      this.setState({isUpdate: true})
    }
    var $this = this;
    var obj = this.state.error;
    if (obj[flag]) {
      return;
    } else {
      switch(flag){
        case 'vn':
          obj['questionVN_error'] = this.state.faq.questionVN === '';
          obj['answerVN_error'] = this.state.faq.answerVN === '';
          obj['vn'] = obj['questionVN_error'] || obj['answerVN_error'];
          this.setState({error:obj},function(){
            if(!$this.state.error[flag]) $this._save();
          });
          break;
        case 'en':
          obj['questionEN_error'] = this.state.faq.questionEN === '';
          obj['answerEN_error'] = this.state.faq.answerEN === '';
          obj['en'] = obj['questionEN_error'] || obj['answerEN_error'];
          this.setState({error:obj},function(){
            if(!$this.state.error[flag]) $this._save();
          });
          break;
        default:
          return true;
      }
    }
  }
  handleCancel() {
    this.context.router.history.push('/faq/');
  }
  handleChangeTab = (event, value) => {
    this.setState({ tab:value });
  }
  handleCopy(){
    var data = this.state.faq;
    data.questionEN = data.questionVN;
    data.answerEN = data.answerVN;
    // data.titleKR = data.titleVN;
    // data.descriptionKR = data.descriptionVN;
    // data.detailKR = data.detailVN;
    this.setState({
      faq: data
    })
  }
  render() {
    const classes = this.props.classes;
    const value = this.state.tab;
    const editorConfig = Utils.setupEditor('others',this.state.options);
    return (
      <div className="page-detail">
        <Toolbar className={classes.toolbar}>
          <Typography className={classes.heading} variant="h5">Thêm/Sửa FAQ</Typography>
        </Toolbar>
        <AppBar position="static" className={classes.subToolbar}>
          <Tabs value={value} onChange={this.handleChangeTab}>
            <Tab label="Tiếng Việt"/>
            <Tab label="English"/>
            {/* <Tab label="한국인"/> */}
          </Tabs>
        </AppBar>
        {value === 0 && <TabContainer>
          <form className={classes.form} noValidate>
            <Typography type="body2" className={classNames(classes.error, !this.state.error.vn && classes.hide)}>{language.VN.error.require}</Typography>
            <Typography type="body2" className={classNames(classes.error, !this.state.failed && classes.hide)}>{this.state.message}</Typography>
            <Button variant="contained" color="secondary" aria-label="copy" onClick={()=>this.handleCopy()}>Copy to [EN]</Button>
            <FormGroup>
              <FormControl>
                <Typography className={classes.label} type="caption">Nhóm FAQ</Typography>
                <Select native={true} id="category" value={this.state.category} onChange={(event) => this.handleValidate(event)}>
                  <option key={0} value="">...</option>
                  {this.state.categories.map((item, index) => (
                    <option key={index + 1} value={item._id}>{item.nameVN}</option>
                  ))}
                </Select>
              </FormControl>
            </FormGroup>
            <TextField id="questionVN" label={language.VN.question} className={classes.textField} value={this.state.faq.questionVN} onChange={(event) => this.handleValidate(event,'vn')} onBlur={(event) => this.handleValidate(event,'vn')} required={true} error={this.state.question_error} margin="normal" InputLabelProps={{
              shrink: true
            }}/>
            <FormLabel className={classes.label_shrink} required={true} error={this.state.error.answerVN_error}>{language.VN.answer}</FormLabel>
            {
              this.state.options['empty']!=='empty' && (<FroalaEditor
                tag='textarea'
                config={editorConfig}
                model={this.state.faq.answerVN}
                onModelChange={(value)=>this.onEditorStateChange('answerVN',value)}
              />)
            }
            <FormGroup row className={classes.formRow}>
              <Button color="default" onClick={(event) => this.handleCancel(event)}>{language.VN.btnCancel}</Button>
              <Button variant="contained" color="primary" onClick={(event) => this.handleSave(event,'vn')}>{language.VN.btnSave}</Button>
            </FormGroup>
          </form>
        </TabContainer>}
        {value === 1 && <TabContainer>
          <form className={classes.form} noValidate>
            <Typography type="body2" className={classNames(classes.error, !this.state.error.en && classes.hide)}>{language.EN.error.require}</Typography>
            <Typography type="body2" className={classNames(classes.error, !this.state.failed && classes.hide)}>{this.state.message}</Typography>
            <TextField id="questionEN" label={language.EN.question} className={classes.textField} value={this.state.faq.questionEN} onChange={(event) => this.handleValidate(event,'en')} onBlur={(event) => this.handleValidate(event,'en')} required={true} error={this.state.error.questionEN_error} margin="normal" InputLabelProps={{
              shrink: true
            }}/>
            <FormLabel className={classes.label_shrink} required={true} error={this.state.error.answerEN_error}>{language.EN.answer}</FormLabel>
            {
              this.state.options['empty']!=='empty' && (<FroalaEditor
                tag='textarea'
                config={editorConfig}
                model={this.state.faq.answerEN}
                onModelChange={(value)=>this.onEditorStateChange('answerEN',value)}
              />)
            }
            <FormGroup row className={classes.formRow}>
              <Button color="default" onClick={(event) => this.handleCancel(event)}>{language.EN.btnCancel}</Button>
              <Button variant="contained" color="primary" onClick={(event) => this.handleSave(event,'en')}>{language.EN.btnSave}</Button>
            </FormGroup>
          </form>
        </TabContainer>}
        {/* {value === 2 && <TabContainer>
          <form className={classes.form} noValidate>
            <Typography type="body2" className={classNames(classes.error, !this.state.error.kr && classes.hide)}>{language.KR.error.require}</Typography>
            <Typography type="body2" className={classNames(classes.error, !this.state.failed && classes.hide)}>{this.state.message}</Typography>
            <TextField id="questionKR" label={language.KR.question} className={classes.textField} value={this.state.faq.questionKR} onChange={(event) => this.handleValidate(event,'kr')} onBlur={(event) => this.handleValidate(event,'kr')} required={true} error={this.state.error.questionKR_error} margin="normal" InputLabelProps={{
              shrink: true
            }}/>
            <FormLabel className={classes.label_shrink} required={true} error={this.state.error.answerKR_error}>{language.KR.answer}</FormLabel>
            {
              this.state.options['empty']!=='empty' && (<FroalaEditor
                tag='textarea'
                config={editorConfig}
                model={this.state.faq.answerKR}
                onModelChange={(value)=>this.onEditorStateChange('answerKR',value)}
              />)
            }
            <FormGroup row className={classes.formRow}>
              <Button color="default" onClick={(event) => this.handleCancel(event)}>{language.KR.btnCancel}</Button>
              <Button variant="contained" color="primary" onClick={(event) => this.handleSave(event,'kr')}>{language.KR.btnSave}</Button>
            </FormGroup>
          </form>
        </TabContainer>} */}
      </div>
    )
  }
}

FAQDetail.propTypes = {
  classes: PropTypes.object.isRequired
};
FAQDetail.contextTypes = {
  router: PropTypes.object
};

export default withStyles(styles)(FAQDetail);
