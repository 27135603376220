import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import {
  Typography,
  Fab,
  Toolbar,
  TextField,
  FormGroup,
  Button,
  FormControl,
  Select,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import SearchIcon from "@material-ui/icons/Search";
import Paper from "@material-ui/core/Paper";
import classNames from "classnames";

import ReactTable from "./shares/react_table";
import theme, { styles } from "../layout/theme";
import Utils from "../api/api";

export class FAQList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      categories: [],
      category: "",
      keyword: "",
      searchClick: false,
      filter: "",
    };
  }
  componentDidMount() {
    var $this = this;
    Utils.getListData("faqgroups", "all", 100, "0,0", "null", 1, function (
      data
    ) {
      //console.log(data);
      if (data.status == "success") {
        $this.setState({ categories: data.results });
      }
    });
  }
  handleSearch(e) {
    var el = document.getElementById("keyword");
    this.setState({ keyword: el.value.trim() });
  }
  filterData(e) {
    const $this = this,
      state = $this.state;

    state[e.target.id] = e.target.value.trim();
    $this.setState(state, function () {
      $this.handleFilter();
    });
  }
  handleFilter() {
    let f = this.state.filter;

    if (f === "") {
      f = {};
    } else {
      f = JSON.parse(f);
    }

    if (this.state.category !== "") {
      f.category = this.state.category;
    } else {
      if (f.category !== undefined) {
        delete f.category;
      }
    }

    this.setState({ filter: JSON.stringify(f) });
  }
  handleAdd() {
    this.context.router.history.push("/faq/add");
  }
  filterData(e) {
    const $this = this,
      state = $this.state;

    state[e.target.id] = e.target.value.trim();
    $this.setState(state, function () {
      $this.handleFilter();
    });
  }
  handleFilter() {
    let f = this.state.filter;

    if (f === "") {
      f = {};
    } else {
      f = JSON.parse(f);
    }

    if (this.state.category !== "") {
      f.category = this.state.category;
    } else {
      if (f.category !== undefined) {
        delete f.category;
      }
    }

    this.setState({ filter: JSON.stringify(f) });
  }
  render() {
    const $this = this;
    const classes = this.props.classes;
    var source = {
      thead: [
        { title: "Câu hỏi", value: "question" },
        { title: "Nhóm", value: "group" },
      ],
      tbody: function (n) {
        return {
          _id: n._id,
          question: n.questionVN,
          group:
            $this.state.categories.length &&
            $this.state.categories.map((x) => {
              return x._id == n.category ? x.nameVN : "";
            }),
        };
      },
    };
    return (
      <div className="faq-page-list">
        <div
          className={classNames(
            classes.auto_toggle,
            this.state.failed && classes.run
          )}
        >
          <Typography variant="body2">{this.state.message}</Typography>
        </div>
        <Toolbar className={classes.toolbar}>
          <Typography className={classes.heading} variant="h5">
            Danh mục FAQ
          </Typography>
        </Toolbar>
        <Paper className={classes.paper}>
          <Toolbar className={classes.tableToolbar}>
            <FormControl className={classes.col1}>
              <FormGroup className={classes.formRowInline}>
                <Button disabled={true}>FAQ</Button>
                <Button
                  onClick={() => {
                    this.context.router.history.push("/faqgroup");
                  }}
                  variant="contained"
                  color="primary"
                >
                  FAQ Group
                </Button>
              </FormGroup>
            </FormControl>
          </Toolbar>
          <Toolbar className={classes.tableToolbar}>
            <FormGroup className={classes.formRowToolbar}>
              <FormGroup className={classes.formRowInline}>
                <FormControl className={classes.col6}>
                  <Typography className={classes.label}>Danh mục</Typography>
                  <Select
                    native
                    id="category"
                    value={$this.state.category}
                    onChange={(event) => this.filterData(event)}
                  >
                    <option value="" key={0}>
                      ...
                    </option>
                    {this.state.categories.map((item, index) => {
                      return (
                        <option key={index + 1} value={item._id}>
                          {item.nameVN}
                        </option>
                      );
                    })}
                  </Select>
                </FormControl>
                <FormControl className={classes.col6}>
                  <TextField
                    type="search"
                    id="keyword"
                    label="Tìm kiếm"
                    className={classNames(
                      classes.textField + " form-control-no-bottom"
                    )}
                    value={this.state.keyword}
                    onChange={(event) =>
                      this.setState({ keyword: event.target.value.trim() })
                    }
                    margin="normal"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </FormControl>
              </FormGroup>
            </FormGroup>
            <Fab
              color="primary"
              aria-label="search"
              className={classes.button}
              onClick={(e) => this.handleSearch(e)}
            >
              <SearchIcon />
            </Fab>
            <Fab
              color="primary"
              aria-label="add"
              className={classes.button}
              onClick={() => this.handleAdd()}
            >
              <AddIcon />
            </Fab>
          </Toolbar>
          <ReactTable
            apiFilter={this.state.filter}
            apiDragOrder={true}
            apiList="faqs"
            apiSingle="faq"
            apiTable="faqs"
            router={this.context.router}
            dataMap={source}
            keyword={this.state.keyword}
            isSearch={this.state.searchClick}
          ></ReactTable>
        </Paper>
      </div>
    );
  }
}

FAQList.propTypes = {
  classes: PropTypes.object.isRequired,
};
FAQList.contextTypes = {
  router: PropTypes.object,
};

export default withStyles(styles)(FAQList);
